import React, { useState } from "react";
import "./Door.css";

function Door() {
  const [isOpen, setIsOpen] = useState(false);
  
  const handleToggleDoor = () => {
    setIsOpen(prevState => !prevState);  // Toggle the door's open state
  };

  return (
    <div
      className={`door ${isOpen ? "open" : ""}`}
      onClick={handleToggleDoor}  // Toggle the door state on click
    >
      <div className="frame">
        <div className="glass"></div>
      </div>
    </div>
  );
}

export default Door;
