import React, { useState, useEffect, useRef} from "react";
import "./App.css";
import Door from "./Door";
import Knob from "./Knob";

function App() {
  const images = ["future.webp", "tri.webp", "scout.webp", "star.webp"]; // replace with your image URLs; 
  const [currentImage, setCurrentImage] = useState(0);
  const roomNames = ["FutureSelf", "Trilosophy", "Scout", "Xenobiology"]; // replace with your room names
  const audio = new Audio('/click.mp3');
  const [metadata, setMetadata] = useState(""); 
  const [utility, setUtility] = useState("");    
  const [displayedMetadata, setDisplayedMetadata] = useState("");
  const [displayedUtility, setDisplayedUtility] = useState("");
  const metadataIntervalRef = useRef(null); // refs to store interval IDs
  const utilityIntervalRef = useRef(null);

  const typeText = async (text, setter, intervalRef) => {
    let accumulatedText = ""; // Local variable to accumulate the text

    // Clear any existing interval before starting a new one
    if (intervalRef.current) {
        clearInterval(intervalRef.current);
    }

    return new Promise((resolve) => {
        let index = 0;
        intervalRef.current = setInterval(() => {
            if (index < text.length) {
                accumulatedText += text[index];  // Update the local variable
                setter(accumulatedText);  // Set the accumulated text to state
                index++;
            } else {
                clearInterval(intervalRef.current);
                resolve();
            }
        }, 100);
    });
};





useEffect(() => {
  // Reset the displayed text before starting the typing effect
  setDisplayedMetadata("");
  setDisplayedUtility("");

  // Call the typeText function for metadata and utility when the room changes
  const typeMetadataAndUtility = async () => {
      await typeText(metadata, setDisplayedMetadata, metadataIntervalRef);
      await typeText(utility, setDisplayedUtility, utilityIntervalRef);
  }

  typeMetadataAndUtility();
}, [metadata, utility]);


  const handleRoomChange = (currentIndex) => {  
    switch(currentIndex) {
        case 0:  // Assuming 0 corresponds to "future.jpg"
            setMetadata("LLM usage detected");
            setUtility("A forecasting simulation");
            break;
        case 1:  // Assuming 1 corresponds to "trilosophy"
            setMetadata("python web app");
            setUtility("a format for social gatherings");
            break;
        case 2:  // Assuming 2 corresponds to "scout"
            setMetadata("markdown database");
            setUtility("an explorers AI experiments");
            break;
        case 3:  // Assuming 3 corresponds to "Xenobiology"
            setMetadata("AI image generation");
            setUtility("a hypothetical alien discovery");
            break;
        default:
            setMetadata("no world selected");
            setUtility("");
    }
  }

  const handleRotate = (deg) => {
    const degreeSegment = 360 / images.length;
    const index = Math.floor((deg + 180) / degreeSegment) % images.length;
    if (index !== currentImage) {
      setCurrentImage(index);
      audio.volume = 0.5;
      audio.play();
      handleRoomChange(index); 
    } else if (metadata === "" && utility === "") {
      // Set initial values only when metadata and utility are empty
      handleRoomChange(index);
    }
};
  const urls = ["https://futureself-b718.onrender.com/", "https://trilosophy-f3jspneibq-km.a.run.app/",  "https://silica.notion.site/sandstone-space-explorations-043827111fb94c398083b61f88dade2b", "https://xenobiology.onrender.com"]; // replace with your URLs

  const handleClick = () => {
      window.location.href = urls[currentImage];
  };

  return (
    <div className="App">
      <div className="container"></div>
      <div className="room-name">{roomNames[currentImage]}</div>
      <Door images={images} currentImage={currentImage} />
      <Knob onRotate={handleRotate} />
      <div className="room-images" style={{ backgroundImage: `url(${images[currentImage]})` }} onClick={handleClick}></div>
      <div className="retro-text">  
        Room Analysis<span className="blinking-cursor">_</span><br /><br />
        Metadata: {displayedMetadata}<br /><br />
        Utility: {displayedUtility}
      </div>
    </div>
  );
}

export default App;