import React, { useState, useRef, useEffect, useCallback } from "react";
import "./Knob.css";

const knobSound = new Audio('/hum.mp3');
knobSound.loop = true;
knobSound.volume = 0.5;

function Knob({ onRotate }) {
    const knobRef = useRef(null);
    const [rotation, setRotation] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    
    const handleRotate = useCallback((clientX, clientY, target) => {
        const rect = target.getBoundingClientRect();
        const x = clientX - rect.left;
        const y = clientY - rect.top;
        const deg = Math.atan2(y - rect.height / 2, x - rect.width / 2) * (180 / Math.PI);
        setRotation(deg);
        onRotate(deg);
    }, [onRotate]);

    const handleMouseMove = (e) => {
        if (isActive) {
            handleRotate(e.clientX, e.clientY, e.target);
        }
    };

    useEffect(() => {
        if (isPlaying) {
            knobSound.play();
        } else {
            knobSound.pause();
            knobSound.currentTime = 0;
        }
    }, [isPlaying]);

    useEffect(() => {
        const knobElement = knobRef.current;

        const directTouchMoveHandler = (e) => {
            e.preventDefault();
            const touch = e.touches[0];
            handleRotate(touch.clientX, touch.clientY, e.target);
        };

        knobElement.addEventListener('touchmove', directTouchMoveHandler, { passive: false });

        return () => {
            knobElement.removeEventListener('touchmove', directTouchMoveHandler);
        };
    }, [handleRotate]);

    return (
        <div 
            ref={knobRef}
            className={`knob ${isActive ? 'active' : ''} ${isPlaying ? 'touched' : ''}`} 
            style={{ transform: `rotate(${rotation}deg)` }} 
            onMouseMove={handleMouseMove}
            onMouseDown={() => { setIsActive(true); setIsPlaying(true); }} 
            onMouseUp={() => { setIsActive(false); setIsPlaying(false); }} 
            onMouseLeave={() => { setIsActive(false); setIsPlaying(false); }} 
            onTouchStart={() => setIsPlaying(true)} 
            onTouchEnd={() => setIsPlaying(false)}
        ></div>
    );
}

export default Knob;
